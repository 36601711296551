import React, { useEffect } from "react";
import { Layout, SEO } from "../components";
import styled from "styled-components";
import { injectIntl } from "gatsby-plugin-intl";
import { PrivacyPolicyEn, PrivacyPolicyPl } from "../sections";

const returnPrivacyPolicy = locale => {
  switch (locale) {
    case "pl":
      return <PrivacyPolicyPl />;
    default:
      return <PrivacyPolicyEn />;
  }
};

const PrivacyPolicy = ({ intl }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "privacy_policy_seo_title" })} />
      <Wrapper>{returnPrivacyPolicy(intl.locale)}</Wrapper>
    </Layout>
  );
};

const Wrapper = styled.section`
  min-height: 80vh;
  margin: 0 auto;
  max-width: 1200px;
  padding: 160px 24px;
  h2 {
    font-size: 46px;
    line-height: 88px;
  }
  @media (max-width: 900px) {
    h2 {
      font-size: 36px;
      line-height: 44px;
    }
  }
`;

export default injectIntl(PrivacyPolicy);
